import React, {useState} from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import HeaderImage from './../../assets/img/home-header.jpg';
import HeaderImageM from './../../assets/img/home-header-m.jpg';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function App() {

  return (
    <div>
      
      <Box
          sx={{
            width: "100%",
            height: "100vh",
            backgroundImage:{
              xs: `url(${HeaderImageM})`, //img src from xs up to md
              md: `url(${HeaderImage})`,  //img src from md and up
            },
            backgroundColor: "#cccccc",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
        <Toolbar >
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "#ffffff", marginLeft: 1 }}>
            RKV Music
          </Typography>
        </Toolbar>
      </Box>
      <CssBaseline />
      <Container maxWidth="md" sx={{ marginTop: "-30vh"}}>
        <Box sx={{ flexGrow: 1, p: 2}}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/6lZGolMQh5OIylSKNOOT7Z?utm_source=generator" width="100%" height="80" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
            </Grid>
            <Grid item xs={12} md={12}>
              <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/4yijxnUynEy45QNTBdGeog?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
            </Grid>
            <Grid item xs={12} md={6}>
              <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/4tsbqOZn58H3kZsZ2HXKQE?utm_source=generator&theme=0" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </Grid>
            <Grid item xs={12} md={6}>
              <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/7fQtUkDRQpYCdxe9MtYIVv?utm_source=generator&theme=0" width="100%" height="380" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </Grid>
            <Grid item xs={12} md={6}>
              <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/4riOfRQlkPACkAj4FreCU7?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </Grid>
            <Grid item xs={12} md={6}>
              <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/3YplGiIsM8OgZ3q7b0NEES?utm_source=generator" width="100%" height="380" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </Grid>
            <Grid item xs={12} md={6}>
              <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/5AA2s6dcWY08UQXuPDETdN?utm_source=generator" width="100%" height="380" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </Grid>
            <Grid item xs={12} md={6}>
              <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/6fRCnxtXvUwB5ddyNIA0J5?utm_source=generator" width="100%" height="380" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </Grid>
            <Grid item xs={12} md={6}>
              <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/5NUQwS1TDjQSJKt8OPlwZs?utm_source=generator" width="100%" height="380" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </Grid>
            <Grid item xs={12} md={6}>
              <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/6eaJ2zx6CVAjqtehK7TVpk?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </Grid>
            <Grid item xs={12} md={6}>
              <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/3ct7aaH0mXe4ymMSug7qUs?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </Grid>
            <Grid item xs={12} md={6}>
              <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/1PmuYV2wIvAZo3epOXnSYq?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </Grid>
            <Grid item xs={12} md={6}>
              <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/5aBovCymfIAkaLzVW484ms?utm_source=generator" width="100%" height="380" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </Grid>
            <Grid item xs={12} md={6}>
              <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/78p2rnO44cPm6bMdYpb1nI?utm_source=generator" width="100%" height="380" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </Grid>
            <Grid item xs={12} md={6}>
              <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/0U0Fz77N4Xu3tgel8RyIpU?utm_source=generator" width="100%" height="380" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </Grid>
            <Grid item xs={12} md={6}>
              <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/2FtrDAcwK2aGokjMgm4kk6?utm_source=generator" width="100%" height="380" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </Grid>
            <Grid item xs={12} md={6}>
              <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/5X8iF378WJdCrQkgWtj1e5?utm_source=generator" width="100%" height="380" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </Grid>
            <Grid item xs={12} md={6}>
              <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/1eBvyasGCDc7A8Bx1UZCc4?utm_source=generator" width="100%" height="380" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </Grid>
            <Grid item xs={12} md={6}>
              <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/7LqVIxfNIbH834mQc0BzPK?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            width: "100%",
            paddingTop: "50px",
            paddingBottom: "80px",
          }}
        >
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "#002a3c", textAlign: "center", fontWeight: "bold" }}>
            RKV Music
          </Typography>
        </Box>
      </Container>
    </div>
  )
}

export default App
